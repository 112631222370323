import "./App.css";
import {
  ChakraProvider,
  Flex,
  Image,
  Heading,
  Text,
} from "@chakra-ui/react";
import Section from "./Section";
import Header from "./Header";
import Footer from "./Footer";
import ContactForm from "./ContactForm";
import { SECONDARY } from "./colours";

function App() {
  return (
    <ChakraProvider>
      <Header />
      <Section
        title="LOCALLY SOURCED, SUSTAINABLE AND SEASONAL CATERING"
        body="Made by Harri. North Wales based."
      />

      <Section
        title="FOR HOLIDAYS"
        secondary
        body="Step away from your kitchen during your holiday – let me handle
                the cooking. Enjoy custom home-cooked meals just the way you
                like them. Whether you fancy dishes ready to pop in the oven or
                want me to handle everything from start to finish, so that you
                can spend a bit more time in the mountains or on the sand."
      />

      <Flex
        flexDir={{ base: "column", md: "row" }}
        bgColor="white"
        justifyContent={"space-around"}
      >
        <Image
          src="https://thehappyfoodie.co.uk/wp-content/uploads/2021/05/flavoursaffron-db2af19f-053a-4057-a62b-1498fe564312_s600x0_q80_noupscale.png"
          height={"72vh"}
          width={{ base: "100vw", md: "25vw" }}
          objectFit={"cover"}
        />
        <Image
          src="https://cdn.apartmenttherapy.info/image/upload/f_auto,q_auto:eco,c_fill,g_center,w_730,h_913/k%2FEdit%2Fecb3d640f68bd9077f4263b1412096e5"
          height={"72vh"}
          width={{ base: "100vw", md: "25vw" }}
          objectFit={"cover"}
          display={{ base: "none", md: "block" }}
        />
        <Image
          src="https://i.pinimg.com/736x/06/77/52/0677528511c23cf128ee1695337284aa.jpg"
          height={"72vh"}
          width={{ base: "100vw", md: "25vw" }}
          objectFit={"cover"}
        />

        <Image
          src="https://food-images.files.bbci.co.uk/food/recipes/chicken_pie_24044_16x9.jpg"
          height={"72vh"}
          width={{ base: "100vw", lg: "25vw" }}
          objectFit={"cover"}
          display={{ base: "none", md: "block" }}
        />
      </Flex>

      <Section
        title="FOR OFFICES"
        body="How about some tasty, catered lunches conveniently delivered to your
        office? Keeping your team happy and fuelled with local goodness,
        sourced from small companies right here in North Wales has never
        been easier. I pick only the freshest, seasonal ingredients from
        organic farms. Get in touch to check out some hampers, salads,
        sandwiches and more."
      />
      <Section
        title="FOR RETREATS"
        secondary
        body="I enjoy yoga, spirituality, writing, hiking and mindfulness—they're
        things I love to do in my spare time. There's nothing I love more
        than creating a safe space for people to come together, share
        skills, support each other, and just spread some love. Cooking for
        retreats is honestly one of my favourite ways to spend my time. I'm
        keen to whip up menus that are balanced, full of nutrients, and give
        everyone that energy boost they need to make the most of the
        retreat. Or maybe you want to host a retreat together? Definitely
        get in touch!"
      />
      <Section
        title="GET IN TOUCH"
        body="Drop me a line to discuss menus, retreat ideas, special occasions or
            joining forces!"
      >
        <ContactForm />
      </Section>
      <Section title="WHAT PEOPLE ARE SAYING" secondary>
          <Flex flexDir={"column"} color={SECONDARY} my='12'>
            <Heading pb="4" size={"lg"}>
            “Happy Fig provided excellent service and professionalism at short notice to cater for a dinner while we were on holiday. Friendly and great food, with a wide selection to cater for different dietary requirements. Would be very happy to work with Harri again.”
            </Heading>
            <Text>- Katherin Talbot, Anglesey</Text>
          </Flex>

          <Flex flexDir={"column"} color={SECONDARY}>
            <Heading pb="4" size={"lg"}>
            “Happy Fig deliver a super quality, reliable catering to fit any budget. We are extremely satisfied with their service and would highly recommend them!”
            </Heading>
            <Text>- Stephen Firks, Bangor</Text>
          </Flex>
          
      </Section>
      <Footer />
    </ChakraProvider>
  );
}

export default App;
