import "./App.css";
import { Flex, Button, Image } from "@chakra-ui/react";
import { FaInstagram } from "react-icons/fa";
import { MAIN, MAIN_BACKGROUND } from "./colours";

export default function Header() {
  return (
    <Flex
      h="10vh"
      justifyContent={"space-between"}
      alignItems={"center"}
      bgColor={MAIN_BACKGROUND}
      px="8"
      py="20"
    >
        <Image
          src="logo.png"
          alt="logo"
          height={250}
          pt='16'
        />
      <Flex alignItems={"center"} fontSize={"40px"} color={MAIN}>
        <FaInstagram
          cursor={"pointer"}
          onClick={() =>
            window.open("https://www.instagram.com/happyfigfoods/?hl=en")
          }
        />
        <Button
          size="lg"
          bg="transparent"
          color={MAIN}
          ml="14"
          display={{ base: "none", md: "block" }}
          border={`2px solid ${MAIN}`}
          borderRadius={"none"}
          _hover={{ bg: MAIN, color: MAIN_BACKGROUND }}
          onClick={() =>
            document
              .getElementById("GET IN TOUCH")
              .scrollIntoView({ behavior: "smooth" })
          }
        >
          Contact me
        </Button>
      </Flex>
    </Flex>
  );
}
